<template>
  <div class="bg-secondary-dark h-full">
    <div>
      <div
        class="text-secondary-light mr-2 mt-2 cursor-pointer"
        @click="handleSidebarToggleClick"
      >
        <BIconArrowRightShort v-if="sidebarCollapsed" class="ml-auto w-5 h-5" />
        <BIconArrowLeftShort v-else class="ml-auto w-5 h-5" />
      </div>

      <div class="flex items-center px-4 pt-3 pb-6 w-auto overflow-hidden">
        <div class="flex space-x-4 overflow-hidden">
          <NuxtLink :to="`/client/${client.client_id_pk}/dashboard`">
            <div
              :class="[
                enrichedClient ? enrichedClient.bgColor : 'bg-red-light',
                sidebarCollapsed ? 'w-8 h-8' : 'w-10 h-10',
                'flex-shrink-0 flex items-center justify-center text-common-white text-md rounded-md font-bold',
              ]"
            >
              {{ enrichedClient?.initials }}
            </div>
          </NuxtLink>
          <div class="flex flex-col">
            <p
              v-if="!sidebarCollapsed"
              class="text-common-white font-bold my-auto truncate"
            >
              {{ enrichedClient?.title }}
            </p>
            <p
              v-if="!sidebarCollapsed"
              class="text-common-white text-sm my-auto truncate"
            >
              {{ getClientMode(client.client_mode) }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-grow flex-col">
        <nav class="flex-1 space-y-2 px-2 justify-center" aria-label="Sidebar">
          <NuxtLink
            v-for="n in nav"
            :key="n.to"
            :to="n.to"
            class="group flex justify-between py-2 px-2 text-md"
          >
            <component
              :is="n.icon"
              :class="[
                n.isActive
                  ? 'text-lilac'
                  : 'text-secondary-lighter group-hover:text-common-white',
                sidebarCollapsed ? 'my-auto mx-auto' : 'mr-3',
                'w-5 h-5 self-center',
              ]"
            />

            <span
              v-if="!sidebarCollapsed"
              :class="[
                n.isActive
                  ? 'text-lilac font-bold'
                  : 'text-secondary-lighter group-hover:text-common-white',
                'flex-1 ',
              ]"
            >{{ n.title }}</span>

            <span v-if="n.alerts > 0 && !sidebarCollapsed" class="bg-secondary-highlight-light text-common-white text-xs font-bold ml-2 px-2.5 py-1 rounded-full my-auto"> {{ n?.alerts }}</span>
          </NuxtLink>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BIconGrid,
  BIconGear,
  BIconClockHistory,
  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconSearch,
  BIconCrosshair,
  BIconJournalText,
  BIconExclamationOctagon
} from 'bootstrap-icons-vue'

import { createEnrichedProjectCard } from '~/utils/other'
import { useCurrentClient } from '~/composables/states'
const route = useRoute()
const { currentClient, getCurrentClientIdToSchema } = useCurrentClient()
const { $gql, $logger, $event } = useNuxtApp()

const props = defineProps({
  clientMode: {
    type: String,
    required: true
  }
})

const alerts = ref({
  dashboard: 0,
  changeHistory: 0,
  ioe: 0,
  ioa: 0,
  settings: 0,
  securityScore: 0
})

// 1536 px is tailwind 2xl breakpount for responsive design
const sidebarCollapsed = ref(window.innerWidth < 1536 || false)
const client = ref(currentClient.value)

const nav = computed(() => {
  const baseNav = [
    {
      title: 'Dashboard',
      to: `/client/${currentClient?.value?.client_id_pk}/dashboard`,
      icon: BIconGrid,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/dashboard`),
      alerts: alerts.value.dashboard
    },
    {
      title: 'Explorer',
      to: `/client/${currentClient?.value?.client_id_pk}/explorer`,
      icon: BIconSearch,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/explorer`),
      alerts: alerts.value.changeHistory
    },
    {
      title: 'Änderungsverlauf',
      to: `/client/${currentClient?.value?.client_id_pk}/change-history`,
      icon: BIconClockHistory,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/change-history`),
      alerts: alerts.value.changeHistory
    },
    {
      title: 'Indicators of Exposure',
      to: `/client/${currentClient?.value?.client_id_pk}/ioe`,
      icon: BIconExclamationOctagon,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/ioe`),
      alerts: alerts.value.ioe
    },
    {
      title: 'Einstellungen',
      to: `/client/${currentClient?.value?.client_id_pk}/settings`,
      icon: BIconGear,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/settings`),
      alerts: alerts.value.settings
    }
  ]

  // extend nav for monitoring mode
  if (props.clientMode === 'monitoring') {
    baseNav.splice(4, 0, {
      title: 'Indicators of Attack',
      to: `/client/${currentClient?.value?.client_id_pk}/ioa`,
      icon: BIconCrosshair,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/ioa`),
      alerts: alerts.value.ioa
    })
    baseNav.splice(5, 0, {
      title: 'Sicherheitslog',
      to: `/client/${currentClient?.value?.client_id_pk}/security-log`,
      icon: BIconJournalText,
      isActive: route.path.startsWith(`/client/${currentClient?.value?.client_id_pk}/security-log`),
      alerts: alerts.value.securityScore
    })
  }

  return baseNav
})
const enrichedClient = computed(() => {
  if (currentClient?.value?.client_name) {
    const client = createEnrichedProjectCard([currentClient.value])[0]
    client.bgColor = 'bg-red-light'
    return client
  }
})

function getClientMode (mode) {
  if (mode === 'audit') {
    return 'Audit'
  } else if (mode === 'quickcheck') {
    return 'QuickCheck'
  } else if (mode === 'monitoring') {
    return 'Monitoring'
  }
}

const alertQuery = `query {
  tasks: s_${getCurrentClientIdToSchema()}_tbl_notifications_aggregate(where: {  _and: [ {notification_deleted: {_eq: false}}, {notification_is_read: {_eq: false}}, {notification_type: {_eq: "task" }} ]}) {
    aggregate {
      count
    }
  }
 }`

async function fetchAlerts () {
  try {
    const { data } = await $gql.query(alertQuery)

    if (data.tasks) {
      alerts.value.settings = data.tasks.aggregate.count
    }
  } catch (e) {
    $logger.error(e)
  }
}

function handleSidebarToggleClick () {
  sidebarCollapsed.value = !sidebarCollapsed.value
}

await fetchAlerts()

// emitted in NotificationList.vue
$event.listen('task:add', () => alerts.value.settings++)
$event.listen('task:remove', () => alerts.value.settings--)

</script>

<style scoped></style>
